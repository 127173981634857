import { CRUD_API_HOST } from 'config'
import GlobalLayout from 'layouts/GlobalLayout'
import type { GetStaticProps } from 'next'
import dynamic from 'next/dynamic'

const Home = dynamic(() => import('modules/Home'), {
  ssr: false,
})

const HomePage = ({ previousEvents = [], premiereEvents = [] }) => {
  return (
    <Home previousEvents={previousEvents} premiereEvents={premiereEvents} />
  )
}

export default HomePage

HomePage.getLayout = function getLayout(page) {
  return <GlobalLayout showNav>{page}</GlobalLayout>
}

/**
 * This function executes once at build time on the server-side.
 * The response is then cached at the Edge and served as static conent
 * alongside the page.
 *
 * The cache can be revalidated using the /api/revalidate webhook.
 *
 */
export const getStaticProps: GetStaticProps = async () => {
  try {
    const premiereEvents = await getPremierEvents()
    const previousEvents = await getPreviousEvents()
    return {
      // Passes the API response into <HomePage /> as props
      props: {
        previousEvents: previousEvents || [],
        premiereEvents: premiereEvents || [],
      },
    }
  } catch (e) {
    throw new Error(`getStaticProps:, ${e.message}`)
  }
}

/**
 * Fetches the featured events rendered above the fold
 *
 * @returns Promise<CuratedEvents[]>
 */
const getPremierEvents = async () => {
  const url = `${CRUD_API_HOST}/api/v1/events/search/curated/en?displayType=PREMIERE`
  const headers = new Headers({
    'Content-Type': 'application/json; charset=utf-8',
    'User-Agent': 'alpha-next',
  })
  const { events } = await fetch(url, {
    method: 'GET',
    headers,
  }).then((res) => res.json())
  return events
}

/**
 * Fetches the previous events rendered at the bottom of the page
 *
 * @returns Promise<CuratedEvents[]>
 */
const getPreviousEvents = async () => {
  const url = `${CRUD_API_HOST}/api/v1/events/search/curated/en?displayType=PREVIOUS`
  const headers = new Headers({
    'Content-Type': 'application/json; charset=utf-8',
    'User-Agent': 'alpha-next',
  })
  const { events } = await fetch(url, {
    method: 'GET',
    headers,
  }).then((res) => res.json())
  return events
}
